import { el } from "redom";
import InterfaceOriginal from "../../../../../../../../../../../vendor/integer-net/magento2-cookieconsent/view/frontend/web/js/cookieconsent/src/lib/Interface";

export default class Interface extends InterfaceOriginal {
  buildModal() {
    const defaultElement = super.buildModal();
    const lists = defaultElement.querySelectorAll('.ccm__list');

    if (lists) {
      Array.from(lists).forEach(element => {
        element.remove();
      })
    }

    const groups = defaultElement.querySelectorAll('.ccm__tabgroup');

    if (groups) {
      Array.from(groups).forEach(element => {
        let switchElement = element.querySelector('.ccm__switch-component');
        const tabHeadElement = element.querySelector('.ccm__tab-head');
        if (!switchElement) {
          switchElement = el('div.ccm__switch-group',
              el('label.ccm__switch',
                  el('span.ccm__switch__slider checked')
              )
          );
        }
        tabHeadElement.prepend(switchElement);
      })
    }

    return defaultElement;
  }

  addEventListeners(elements) {
    super.addEventListeners(elements);

    if (!window.CookieConsent.config.categories['youtube']) {
      return;
    }

    const buttonsYouTubeAccept = document.querySelectorAll('[data-youtube-accept]');

    buttonsYouTubeAccept.forEach(
      button => {
        button.addEventListener(
          'click',
          () => {
            window.CookieConsent.config.categories['youtube'].wanted = true;
            window.CookieConsent.config.categories['youtube'].checked = true;
  
            this.buildCookie((cookie) => {
              this.setCookie(cookie, () => {});
            });
  
            window.dispatchEvent(new CustomEvent('update-cookie-consent'));
          }
        )
      }
    )
  }
}
