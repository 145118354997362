import InsertScriptFilter from '../../../../../../../../../../../vendor/integer-net/magento2-cookieconsent/view/frontend/web/js/cookieconsent/src/lib/InsertScriptFilter';
import ScriptTagFilter from '../../../../../../../../../../../vendor/integer-net/magento2-cookieconsent/view/frontend/web/js/cookieconsent/src/lib/ScriptTagFilter';
import WrapperFilter from '../../../../../../../../../../../vendor/integer-net/magento2-cookieconsent/view/frontend/web/js/cookieconsent/src/lib/WrapperFilter';
import LocalCookieFilter from '../../../../../../../../../../../vendor/integer-net/magento2-cookieconsent/view/frontend/web/js/cookieconsent/src/lib/LocalCookieFilter';
import Interface from './Interface';
import Configuration from '../../../../../../../../../../../vendor/integer-net/magento2-cookieconsent/view/frontend/web/js/cookieconsent/src/lib/Configuration';
import RemoveCookies from '../../../../../../../../../../../vendor/integer-net/magento2-cookieconsent/view/frontend/web/js/cookieconsent/src/lib/RemoveCookies';

export default class CookieConsent {

  init(configObject) {
    new Configuration(configObject);

    const removeCookies = new RemoveCookies();
    const insertScriptFilter = new InsertScriptFilter();
    const scriptTagFilter = new ScriptTagFilter();
    const wrapperFilter = new WrapperFilter();
    const localCookieFilter = new LocalCookieFilter();

    removeCookies.init();
    insertScriptFilter.init();
    scriptTagFilter.init();
    wrapperFilter.init();
    localCookieFilter.init();

    const UI = new Interface();

    UI.buildInterface(() => {
      UI.addEventListeners();
    });
  }

}
